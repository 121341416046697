<template>
  <div class="">
    <div class="mb-2 d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between">
      <div class="mb-1 mb-sm-0">
        สถานะ
        <b-badge class="ml-50" :variant="csFormat.formatVariantStatusBadge(statusReview)">
          {{ csFormat.formatStatusBadge(statusReview) }}
        </b-badge>
      </div>
      <b-button
        size="sm"
        variant="primary"
        :disabled="timeline.length <= 0"
        @click="$refs['modal-review-transaction'].show()"
      >
        ประวัติการรีวิว
      </b-button>
    </div>
    <validation-observer ref="form" v-if="canReview">
      <b-form>
        <b-row cols="1">
          <b-col>
            <b-form-group label="เลือกอนุมัติวอลเปเปอร์" label-for="status">
              <v-select v-model="status" label="labelTH" :options="cOptionsStatus" :clearable="false" />
            </b-form-group>
          </b-col>
          <b-col v-if="status && status.key === 'reject'">
            <b-form-group label="รายละเอียด" label-for="note">
              <validation-provider #default="{ errors }" name="รายละเอียด" rules="required|min:10">
                <b-form-textarea
                  id="note"
                  v-model="note"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  placeholder="ระบุรายละเอียดเพิ่มเติม"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="รูปที่เกี่ยวข้อง (ไม่จำเป็น)" label-for="imagesReject">
              <upload-image-widget @uploadFile="onUploadFile" />
            </b-form-group>

            <div v-for="(img, index) in imagesReject" :key="index" style="position: relative;">
              <box-image-preview-widget :src="img.blobUrl" />
              <b-btn @click="deleteImageReject(index)" class="btn-delete-image-reject" variant="danger" size="sm">
                ลบ
              </b-btn>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- <div v-else>
      <div class="">
        <TimelineReviewWidget :listData="timeline" />
      </div>
    </div> -->
    <b-modal ref="modal-review-transaction" size="lg" hide-footer title="ประวัติรีวิว">
      <TimelineReviewWidget :listData="timeline" />
    </b-modal>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, min } from '@validations'
import TimelineReviewWidget from '@/widgets/TimelineReviewWidget.vue'

export default {
  components: { vSelect, ValidationProvider, ValidationObserver, TimelineReviewWidget },
  props: {
    timeline: {
      type: Array,
      default() {
        return []
      },
    },
    canReview: {
      type: Boolean,
      default() {
        return true
      },
    },
    statusReview: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      min,
      note: '',
      status: null,
      imagesReject: [],
      optionsStatus: [
        { key: 'approve', labelTH: 'อนุมัติ' },
        { key: 'reject', labelTH: 'ไม่อนุมัติ' },
      ],
    }
  },
  computed: {
    cOptionsStatus() {
      if (this.statusReview === 'reject') {
        return [{ key: 'approve', labelTH: 'อนุมัติ' }]
      }

      if (this.statusReview === 'approve') {
        return [{ key: 'reject', labelTH: 'ไม่อนุมัติ' }]
      }

      return [
        { key: 'approve', labelTH: 'อนุมัติ' },
        { key: 'reject', labelTH: 'ไม่อนุมัติ' },
      ]
    },
  },
  methods: {
    async validateForm() {
      const validate = await this.$refs.form.validate()
      if (!validate) return null

      if (!this.status) {
        this.gDisplayToast('โปรดระบุข้อมูลให้ครบถ้วน', 'โปรดเลือกอนุมัติที่ช่อง เลือกอนุมัติวอลเปเปอร์', 'danger')
        return null
      }

      return { status: this.status, note: this.note, imagesReject: this.imagesReject }
    },
    onUploadFile(fileObj) {
      console.log('onUploadFile', fileObj)
      this.imagesReject = [...this.imagesReject, { ...fileObj }]
    },
    deleteImageReject(idx) {
      this.imagesReject.splice(idx, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-delete-image-reject {
  position: absolute;
  top: 5px;
  right: 5px;
}
</style>
