<template>
  <div>
    <b-overlay :show="loading">
      <div v-if="available">
        <form-wizard
          ref="ref-tab-details-wallpaper"
          color="#7367F0"
          :title="null"
          :subtitle="null"
          shape="square"
          :finish-button-text="canReview ? 'รีวิว' : 'กลับไปดูรูปรออนุมัติ'"
          back-button-text="ย้อนกลับ"
          next-button-text="ต่อไป"
          class="mb-3"
          lazy
          @on-change="onTabChange"
          @on-complete="onStepComplete"
        >
          <div class="mb-1">
            <tab-translations-widget v-show="tabActive < 2" @onChange="onTabTranslationsChange" />
          </div>
          <b-alert
            v-if="approveStatus === 'checking'"
            :variant="$store.getters['wallpapers/isNewWallpaperBeforeReview'] ? 'success' : 'warning'"
            show
          >
            <div class="alert-body">
              <span v-if="$store.getters['wallpapers/isNewWallpaperBeforeReview']">
                <strong>วอลเปเปอร์ใหม่</strong> วอลเปเปอร์ที่ครีเอเตอร์ได้อัปโหลดมาใหม่ และยังไม่เคยถูกรีวิว
              </span>
              <span v-else>
                <strong>แก้ไขวอลเปเปอร์</strong> วอลเปเปอร์ที่เคยถูกรีวิวไปแล้ว และมีการแก้ไขแล้วส่งรีวิวมาใหม่
              </span>
            </div>
          </b-alert>
          <b-card no-body class="p-1 mt-1 mb-1">
            <span>ร้านค้า: {{ previewShopDetails }}</span>
          </b-card>
          <tab-content title="รูปทั้งหมด" lazy>
            <ImageDetailsStepOne :translation-tab-key="keyTranslation" />
          </tab-content>
          <tab-content title="รายละเอียดรูปหน้าหลัก" lazy :before-change="updateDetailsStepFour">
            <!-- <UploadWallpaperStepTwo ref="refUploadWallpaperStepTwo" /> -->
            <ImageDetailsStepTwo ref="refImageDetailsStepTwo" :translation-tab-key="keyTranslation" />
          </tab-content>
          <tab-content title="ตำแหน่งรูปภาพ" lazy>
            <ImageDetailsStepThree :active="tabActive === 2" />
          </tab-content>
          <tab-content title="พรีวิว" lazy>
            <ImageDetailsStepFour ref="refImageDetailsStepFour" :focus="tabActive === 3" />
            <!-- <UploadWallpaperStepFour /> -->
          </tab-content>
          <tab-content title="รีวิว" lazy>
            <ImageDetailsStepFive
              ref="refImageDetailsStepFive"
              :can-review="canReview"
              :timeline="timeline"
              :status-review="approveStatus"
            />
          </tab-content>
        </form-wizard>
      </div>
      <div v-else-if="!loading" class="my-2">
        <h2 class="text-center text-muted">ไม่พบข้อมูลรูปที่ค้นหา</h2>
      </div>
    </b-overlay>
    <!-- <pre>{{ JSON.stringify(wallpaperDatas.allImage, null, 2) }}</pre> -->
    <!-- <pre>{{ JSON.stringify(timeline, null, 2) }}</pre> -->
    <!-- <pre>{{ JSON.stringify(wallpaperDatas, null, 2) }}</pre> -->
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapMutations, mapState } from 'vuex'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import ImageDetailsStepOne from '../components/ImageDetailsStepOne.vue'
import ImageDetailsStepTwo from '../components/ImageDetailsStepTwo.vue'
import ImageDetailsStepThree from '../components/ImageDetailsStepThree.vue'
import ImageDetailsStepFour from '../components/ImageDetailsStepFour.vue'
import ImageDetailsStepFive from '../components/ImageDetailsStepFive.vue'

export default {
  components: {
    FormWizard,
    TabContent,
    ImageDetailsStepOne,
    ImageDetailsStepTwo,
    ImageDetailsStepThree,
    ImageDetailsStepFour,
    ImageDetailsStepFive,
    ToastificationContent,
  },
  data() {
    return {
      loading: false,
      available: false,
      canReview: false,
      timeline: [],
      tabActive: 0,
      approveStatus: '',
      keyTranslation: 'th',
    }
  },
  async created() {
    const { id, canReview } = this.$route.params

    // console.log(id !== 0, typeof id)
    if (id !== '0' && id !== 0) {
      this.loading = true
      const res = await this.api.get(`api/admin/admin-topic-images/${id}`).catch(() => null)
      // console.log('[RES]: get image details => ', res)

      if (!res) {
        this.gDisplayToast('พบข้อผิดพลาด', 'ไม่สามารถร้องขอข้อมูลรูปนี้ได้', 'danger')
        this.$router.push({ name: 'images-management-review', replace: true })
        return
      }

      if (res?.message) {
        this.gDisplayToast('พบข้อผิดพลาด', res?.message, 'danger')
        this.$router.push({ name: 'images-management-review', replace: true })
        return
      }

      if (typeof res === 'object') {
        let oldWallpaperData = null
        if (res.image_timeline.length > 0 && (res.approved_status === 'checking' || res.approved_status === 'wait')) {
          const respOldData = await this.api
            .getV2(`api/admin/admin-topic-images-dummy-datas/${res.id}`)
            .catch(() => null)

          if (respOldData && respOldData.code === 200 && typeof respOldData.data === 'object') {
            oldWallpaperData = respOldData.data
          } else {
            this.gDisplayToast('พบข้อผิดพลาด', 'ไม่สามารถแสดงข้อมูลเก่าก่อนที่จะส่งมารีวิวใหม่ได้', 'danger')
          }
        }

        this.SET_DATA_EDIT_IMAGE({ data: res, navigate: false, oldData: oldWallpaperData })
        this.available = true
        this.timeline = res.image_timeline
        this.canReview = true
        this.approveStatus = res.approved_status
        setTimeout(() => {
          // eslint-disable-next-line no-unused-expressions
          this.$refs['ref-tab-details-wallpaper']?.activateAll()
        }, 250)
      }
      this.loading = false
    }
  },
  beforeDestroy() {
    setTimeout(() => {
      this.RESET_WALLPAPER_STATE()
    }, 100)
  },
  methods: {
    ...mapMutations('wallpapers', ['SET_DATA_EDIT_IMAGE', 'RESET_WALLPAPER_STATE']),
    onTabChange(_, nextIndex) {
      // console.log('nextIndex', nextIndex)
      this.tabActive = nextIndex
    },
    async callApiToApproveOrReject(id, type, note = '') {
      // console.log('callApiToApproveOrReject => ', { id, note, type, imagesReject })

      this.loading = true
      const res = await this.api.post(
        'api/admin/admin-image-timeline/create',
        {
          topic_image_id: id,
          action_type: type,
          note,
        },
        false,
      )
      // console.log('[RES]: callApiToApproveOrReject => ', res)
      if (res === 'รูปอาจถูกลบไปแล้ว') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'ไม่พบรูปที่ต้องการทำรายการ',
            icon: 'BellIcon',
            text: 'ไม่สามารถทำการรายต่อได้ เพราะเหมือนรูปจะถูกลบไปแล้ว',
            variant: 'danger',
          },
        })
      }
      this.loading = false
      this.$router.push({ name: 'images-management-checking' })
    },
    async callApiToRejectWithImage(id, type, note = '', imagesReject = []) {
      const formData = new FormData()
      formData.append('topic_image_id', id)
      formData.append('note', note)
      formData.append('action_type', type)

      if (imagesReject && imagesReject?.length > 0) {
        imagesReject.forEach(el => {
          formData.append('files[]', el.file)
        })
      }
      this.loading = true
      const resp = await this.api
        .postV2('api/admin/admin-image-timeline/reject-image', formData, this)
        .catch(() => null)
      this.loading = false

      if (!resp) return

      if (resp.code === 405) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'ไม่พบรูปที่ต้องการทำรายการ',
            icon: 'BellIcon',
            text: 'ไม่สามารถทำการรายต่อได้ เพราะเหมือนรูปจะถูกลบไปแล้ว',
            variant: 'danger',
          },
        })
      }

      this.$router.push({ name: 'images-management-checking' })
    },
    async onStepComplete() {
      if (!this.canReview) {
        this.$router.push({ name: 'images-management-review' })
        return ''
      }
      const validate = await this.$refs.refImageDetailsStepFive.validateForm()
      if (!validate) return ''

      const { id } = this.$route.params
      const { status, note, imagesReject } = validate
      // console.log('onStepComplete => ', { id, status, note })
      if (status.key === 'approve') {
        const resAlert = await this.$swal({
          icon: 'success',
          text: 'อนุมัติรูปวอเปเปอร์ใช่หรือไม่',
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          allowOutsideClick: false,
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
        })

        const { isConfirmed } = resAlert
        if (!isConfirmed) return ''
        return this.callApiToApproveOrReject(id, status.key)
      }

      const resAlert = await this.$swal({
        icon: 'error',
        showCloseButton: false,
        showCancelButton: true,
        focusConfirm: false,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        html: `<div class="mb-2"><p>ไม่อนุมัติรูปวอเปเปอร์ใช่หรือไม่</p><p>รายละเอียด : ${note}</p></div>`,
        buttonsStyling: false,
        allowOutsideClick: false,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
      })

      const { isConfirmed } = resAlert
      if (!isConfirmed) return ''
      return this.callApiToRejectWithImage(id, status.key, note, imagesReject)
    },
    async updateDetailsStepFour() {
      return new Promise(async (resolve, reject) => {
        const optionsFonts = await this.$refs.refImageDetailsStepTwo.getOptionsFonts()
        this.$refs.refImageDetailsStepFour.updateOptionsFonts(optionsFonts)
        this.$refs.refImageDetailsStepFour.updateMainOptionsFont()
        resolve(true)
      })
    },
    onTabTranslationsChange(values) {
      // console.log('onTabTranslationsChange', values)
      this.keyTranslation = values.local_code
    },
  },
  computed: {
    ...mapState('wallpapers', ['wallpaperDatas']),
    previewShopDetails() {
      const { user } = this.wallpaperDatas

      if (user) {
        return `${user?.shop_name} (${user?.email})`
      }

      return '-'
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
